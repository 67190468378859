import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ApiContext } from '../context/ApiContext';
import { Typography } from '@mui/material';

export default function CreateSceneModal({onSceneAdded}) {
 const [open, setOpen] = React.useState(false);
 const [sceneName, setSceneName] = useState('');
 const [isLoading, setIsLoading] = useState(false); // New state for tracking loading
 const {sceneOperations, getSceneByName} = useContext(ApiContext);

 const handleClickOpen = () => {
    setOpen(true);
 };

 const handleClose = () => {
    setOpen(false);
 };

 const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Start loading
    try {
        await sceneOperations(sceneName);
        const scene = await getSceneByName(sceneName, '');
        onSceneAdded(scene.resource[0]);
        // On success, close the modal
        handleClose();
    } catch (error) {
        console.error(error);
        // Optionally handle error here
    } finally {
        setIsLoading(false); // End loading regardless of success or failure
    }
 };

 return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Create Scene
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create a Scene</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              autoFocus
              margin="dense"
              id="sceneName"
              label="Scene Name"
              type="text"
              fullWidth
              value={sceneName}
              onChange={(e) => setSceneName(e.target.value)}
            />
            <DialogActions style={{ justifyContent: 'center' }}> {/* Center-align buttons */}
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" disabled={isLoading}>
                {isLoading ? <Typography>Loading</Typography> : <Typography>Submit</Typography>}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
 );
}
