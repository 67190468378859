import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext'; // Assuming you have an AuthContext
import SceneNameModal from './AddScene';
import Login from './Login';
import { ApiContext } from '../context/ApiContext';
import MUIDataTable from 'mui-datatables'
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Modal } from '@mui/material'; // Assuming Material-UI for modal and button
import { useForm } from 'react-hook-form'; // For form handling
import ThreeDAssetForm from './ThreeDAssetForm';

function Home() {
  const { getAllScenes } = useContext(ApiContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState([]);
  const [selectedObjectID, setSelectedObjectID] = useState(null);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsNewModalOpen(false)
  };

  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const handleOpenNewModal = () => {
    setIsNewModalOpen(true);
    handleClose()
  };

  const handleClick = (event, sceneId) => {
    setAnchorEl(event.currentTarget);
    setSelectedObjectID(sceneId)
  };

  const [modalOpen, setModalOpen] = useState(false);
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    // Handle form submission, e.g., send data to server
    setModalOpen(false); // Close modal after submission
  };
  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAllScenes("");
        setData(result.resource); // Assuming getAllScenes returns the data directly
        console.log(result.resource)
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
    

    fetchData();
  }, []); // Empty dependency array means this effect runs once on mount

  const handleSceneAdded = (newScene) => {
    // Option 1: Directly update state with the new scene
    setData(prevData => [...prevData, newScene]);

  };

  // Sample table data
  const columns = [
    {
      name: "_id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
        width: '100px'
      }
    },
    {
      name: "sceneName",
      label: "Name",
      options: {
        filter: true,
        sort: false,
        width: '100px'
      }
    },
    {
      name: "objectID",
      label: "Scene ID",
      options: {
        filter: true,
        sort: false,
        width: '100px'
      }
    },
    {
      name: "action",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        width: '100px',
        customBodyRender: (value, tableMeta, updateValue) => {
          const sceneId = tableMeta.rowData[2];
          console.log(tableMeta)
          return (
            <div>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={event => handleClick(event, sceneId)}
              >
                <MoreVertIcon />
              </IconButton>
            </div>
          );
        }
      }
    }
  ];

  return (
      <>
      <div style={{ maxHeight: '600px', mindWidth: '900px', overflowY: 'auto' }}>
        <MUIDataTable
          title={"Scene List"}
          data={data}
          columns={columns}
          options={{
            responsive: "vertical",
            selectableRowsHeader: false,
            selectableRowsHideCheckboxes: true,
            customToolbar: () => (
                <SceneNameModal isOpen={isModalOpen} onClose={handleCloseModal} onSceneAdded={handleSceneAdded}/>
            ),
          }}
        />
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleOpenNewModal}>
            Create 3D Asset
          </MenuItem>
          <MenuItem onClick={handleOpenNewModal}>
            View Assets
          </MenuItem>
        </Menu>
        <Modal open={isNewModalOpen} onClose={handleCloseModal}>
          <ThreeDAssetForm onSubmit={onSubmit} onClose={handleCloseModal} sceneId={selectedObjectID} />
        </Modal>
        </div>
      </>
  )
}

export default Home;