import React, { useContext } from 'react';
import Util from '../Util/Util';
import { useForm, Controller } from 'react-hook-form';
import { Button, Box } from '@mui/material';
import '../styles/ThreeDAsset.css'
import { ApiContext } from '../context/ApiContext';

function ThreeDAssetForm({ onClose, sceneId }) {
  const {assetOperations} = useContext(ApiContext);
  const { handleSubmit, control, register } = useForm();
  const user = JSON.parse(window.localStorage.getItem('user'));
  const scaleOptions = [
    { value: 'metric', label: 'Metric' },
    { value: 'imperial', label: 'Imperial' },
    { value: 'custom', label: 'Custom' },
  ];

  const onSubmit = async (data) => {
    console.log(data)
    // Transform the data into the desired request body format
    const body = 
        {
          ...data,
          objectID: Util.generateOID('MeshAssetDataBucket', user.username),
          createTime: Util.formatDate(),
          modifyTime: "",
          deleteTime: "",
          creatorName: user.username,
          sceneID: sceneId,
          downloadable: false,
          loadCompatibility: 161,
          realTransform: {
            position: {
              x: parseInt(data.threedTransformOffset.position.x, 10),
              y: parseInt(data.threedTransformOffset.position.y, 10),
              z: parseInt(data.threedTransformOffset.position.z, 10),
            },
            rotation: {
              x: 0, // Assuming default rotation values
              y: 0,
              z: 0,
              w: 1,
            },
            localScale: {
              x: 1, // Assuming default scale values
              y: 1,
              z: 1,
            },
          },
          createdByMeshScanningAgent: false,
          makeVRInvisibleAfterLoading: false,
          isEnacted: false,
          displayAssetType: "",
        };

    await assetOperations(body);
    // Here you can make the API request with the requestBody
  };

  return (
    <div className='form-container'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ display: 'grid', gap: '10px' }}>
          <label>Asset Name: </label>
          <input
            type="text"
            name="assetName"
            placeholder="Asset Name"
            {...register('assetName', { required: true })}
          />

          {/* Dropdown for assetType */}
          <label>Asset Type: </label>
          <select name="assetType" {...register('assetType', { required: true })}>
            <option value="">Select Asset Type</option>
            <option value="Mesh">Mesh</option>
            <option value="type2">Type 2</option>
            {/* Add more options as needed */}
          </select>

          {/* File input for filePath */}
          <input type="file" name="filePath" {...register('filePath', { required: true })} />

          {/* 3D Transform Offset */}
          <label>3D Transform Offset</label>
          <div style={{ display: 'contents', justifyContent: 'space-between', marginBottom: '10px' }}>
            <div style={{ gap: 10 }}>
              <label>Position: </label>
              <Controller
                name="threedTransformOffset.position.x"
                control={control}
                defaultValue={0}
                render={({ field }) => <input {...field} placeholder="X" style={{ marginRight: '1rem' }} />}
              />
              <Controller
                name="threedTransformOffset.position.y"
                control={control}
                defaultValue={0}
                render={({ field }) => <input {...field} placeholder="Y" style={{ marginRight: '1rem' }} />}
              />
              <Controller
                name="threedTransformOffset.position.z"
                control={control}
                defaultValue={0}
                render={({ field }) => <input {...field} placeholder="Z" style={{ marginRight: '1rem' }} />}
              />
            </div>
            <div>
              <label>Rotation: </label>
              {/* Assuming you have rotation fields similar to position fields */}
              <Controller
                name="threedTransformOffset.rotation.x"
                control={control}
                defaultValue={0}
                render={({ field }) => <input {...field} placeholder="X" style={{ marginRight: '1rem' }} />}
              />
              <Controller
                name="threedTransformOffset.rotation.y"
                control={control}
                defaultValue={0}
                render={({ field }) => <input {...field} placeholder="Y" style={{ marginRight: '1rem' }} />}
              />
              <Controller
                name="threedTransformOffset.rotation.z"
                control={control}
                defaultValue={0}
                render={({ field }) => <input {...field} placeholder="Z" style={{ marginRight: '1rem' }} />}
              />
            </div>
          </div>
          <Controller
 name="fileScale"
 control={control}
 defaultValue={[]}
 render={({ field }) => (
    <div>
      <label>File Scale: </label>
      {scaleOptions.map((option) => (
        <label key={option.value}>
          <input
            type="checkbox"
            value={option.value}
            checked={field.value.includes(option.value)}
            onChange={(e) => {
              const { value, checked } = e.target;
              if (checked) {
                // If the checkbox is checked, set the field value to an array containing only this value
                field.onChange([value]);
              } else {
                // If the checkbox is unchecked, set the field value to an empty array
                field.onChange([]);
              }
            }}
          />
          {option.label}
        </label>
      ))}
      {field.value.includes('custom') && (
        <Controller
          name="customScaleValue"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <input
              type="text"
              placeholder="Custom Value"
              style={{marginLeft: '.5rem'}}
              {...field}
            />
          )}
        />
      )}
    </div>
 )}
/>
<Controller
 name="3dProcessing"
 control={control}
 defaultValue={[]}
 render={({ field }) => (
    <div>
      <label>3D Processing: </label>
      {['Double Sided Mesh', 'Point Cloud', 'Include Directory'].map((option) => (
        <label key={option}>
          <input
            type="checkbox"
            value={option}
            checked={field.value.includes(option)}
            onChange={(e) => {
              const { value, checked } = e.target;
              if (checked) {
                field.onChange([...field.value, value]);
              } else {
                field.onChange(field.value.filter((v) => v !== value));
              }
            }}
          />
          {option}
        </label>
      ))}
    </div>
 )}
/>


          {/* Additional fields as needed */}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="submit">Submit</Button>
            <Button onClick={onClose}>Close</Button>
          </Box>
        </div>
      </form>
    </div>
  );
}

export default ThreeDAssetForm;