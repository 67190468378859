import React, { createContext } from 'react';
import Util from '../Util/Util';

export const ApiContext = createContext();

const ApiProvider = ({ children }) => {
    // Define your API calls here

    const apiUrl = process.env.REACT_APP_API_URL;
    const apiLogin = async (username, password) => {
        // Example API call
        const response = await fetch(`${apiUrl}/api/v2/mongodb/_table/FVRUserDataBucket?filter=(username=${username})and(password=${password})`, {
            method: 'GET', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            }
        });
        const data = await response.json();
        return data.resource[0];


    };

    const getSceneByName = async (sceneName, modifyTime) => {
        // Example API call
        const response = await fetch(`${apiUrl}/FVRSceneDataBucket?sceneName=${sceneName}&modifyTime=${modifyTime}`, {
            method: 'GET', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            }
        });
        const data = await response.json();
        return data;
    };
    const getSceneById = async (objectId, modifyTime) => {
        // Example API call
        const response = await fetch(`${apiUrl}/FVRSceneDataBucket?objectID=${objectId}&modifyTime=${modifyTime}`, {
            method: 'GET', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            }
        });
        const data = await response.json();
        return data;
    };

    const getEnvironment = async (sceneID, modifyTime) => {
        // Example API call
        const response = await fetch(`${apiUrl}/FVREnvironmentDataBucket?sceneID=${sceneID}&modifyTime=${modifyTime}`, {
            method: 'GET', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            }
        });
        const data = await response.json();
        return data;
    };

    const getAllScenes = async (modifyTime) => {
        // Example API call
        const response = await fetch(`${apiUrl}/FVRSceneDataBucket?modifyTime=${modifyTime}`, {
            method: 'GET', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            }
        });
        const data = await response.json();
        return data;
    };

    const createScene = async (body) => {
        // Example API call
        const response = await fetch(`${apiUrl}`, {
            method: 'POST', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "FVRSceneDataBucket",
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const updateScene = async (scene, body) => {
        // Example API call
        const sceneID = scene.resource[0].objectID;
        const modifyTime = ""
        const response = await fetch(`${apiUrl}`, {
            method: 'PATCH', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "FVRSceneDataBucket",
                filters: `(objectID=${sceneID})and(modifyTime=${modifyTime})`,
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const updateMesh = async (mesh, body) => {
        // Example API call
        const meshID = mesh.resource[0].objectID;
        const modifyTime = ""
        const response = await fetch(`${apiUrl}`, {
            method: 'PATCH', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "MeshAssetDataBucket",
                filters: `(objectID=${meshID})and(modifyTime=${modifyTime})`,
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const createEnvironment = async (sceneName, user) => {
        // Example API call
        const scene = await getSceneByName(sceneName, "");
        const response = await fetch(`${apiUrl}`, {
            method: 'POST', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "FVREnvironmentDataBucket",
                resource: [
                    {
                        objectID: Util.generateOID('FVREnvironmentDataBucket', 'ry'),
                        createTime: Util.formatDate(),
                        modifyTime: "",
                        deleteTime: "",
                        creatorName: 'ry',
                        sceneID: scene.resource[0].objectID,
                        isEnacted: false,
                        sceneObjectIDs: [],
                        currDisplayMode: 0,
                        preloadedModelID: "",
                        scannedPointCloudID: "",
                        realTimeArchetypeInfoList: [],
                        useSpatialAudio: false,
                        highAccGrabSync: true
                    }
                ]

            }),
        });
        const data = await response.json();
        return data;
    };

    const createShelf = async (body) => {
        // Example API call
        const response = await fetch(`${apiUrl}`, {
            method: 'POST', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "SceneShelfDataBucket",
                resource: [
                    body
                ]
            }),
        });
        // const data = await response.json();
        // return data;
    };

    const createMesh = async (body) => {
        // Example API call
        const req = body.body
        const response = await fetch(`${apiUrl}`, {
            method: 'POST', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "MeshAssetDataBucket",
                resource: [
                    body
                ]
            }),
        });
        // const data = await response.json();
        // return data;
    };

    const createThreeDAssetTask = async (body) => {
        // Example API call
        const req = body.body
        const response = await fetch(`${apiUrl}`, {
            method: 'POST', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "ThreeDAssetProcessTask",
                resource: [
                    body
                ]
            }),
        });
        // const data = await response.json();
        // return data;
    };

    const createThreeDAsset = async (body) => {
        // Example API call
        const req = body.body
        const response = await fetch(`${apiUrl}`, {
            method: 'POST', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "ThreeDAssetDataBucket",
                resource: [
                    body
                ]
            }),
        });
        // const data = await response.json();
        // return data;
    };

    const getMesh = async (sceneId, assetName) => {
        try {
            const modifyTime = '';
            const response = await fetch(`${apiUrl}/MeshAssetDataBucket?modifyTime=${modifyTime}&sceneID=${sceneId}&assetName=${assetName}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            // Check if the response was successful
            if (!response.ok) {
                throw new Error(`HTTP error status: ${response.status}`);
            }


            const data = await response.json();

            return data;
        } catch (error) {
            console.error('Failed to fetch mesh data:', error);
            throw error; // Rethrow the error to allow further handling up the call stack
        }
    };

    const getThreeDAsset = async (sceneId, modifyTime) => {
        try {
            const response = await fetch(`${apiUrl}/ThreeDAssetDataBucket?filtermodifyTime=${modifyTime}&sceneID=${sceneId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            // Check if the response was successful
            if (!response.ok) {
                throw new Error(`HTTP error status: ${response.status}`);
            }


            const data = await response.json();

            return data;
        } catch (error) {
            console.error('Failed to fetch mesh data:', error);
            throw error; // Rethrow the error to allow further handling up the call stack
        }
    };

    const sceneOperations = async (sceneName) => {
        // Example API call
        // const user = JSON.parse(window.localStorage.getItem('user'));
        await createScene({
            objectID: Util.generateOID('FVRSceneDataBucket', 'ry'),
            createTime: Util.formatDate(),
            modifyTime: "",
            deleteTime: "",
            creatorName: 'ry',
            sceneName: sceneName,
            environmentID: "",
            businessAccountID: 'Administrator',
            networkLoadLocked: false,
            contentTypes: [],
            checkedOutUser: ""
        });

        const scene = await getSceneByName(sceneName, "");
        await createEnvironment(sceneName, 'ry');
        const env = await getEnvironment(scene.resource[0].objectID, "");
        await updateScene(scene,
            {
                modifyTime: Util.formatDate()
            });
        await createScene({
            objectID: scene.resource[0].objectID,
            createTime: Util.formatDate(),
            modifyTime: "",
            deleteTime: "",
            creatorName: 'ry',
            sceneName: sceneName,
            environmentID: env.resource[0].objectID,
            businessAccountID: 'Administrator',
            networkLoadLocked: false,
            contentTypes: ["FVREnvironment"],
            checkedOutUser: ""
        });
        await createShelf({
            objectID: Util.generateOID('SceneShelfDataBucket', 'ry'),
            createTime: Util.formatDate(),
            modifyTime: "",
            deleteTime: "",
            creatorName: 'ry',
            sceneID: scene.resource[0].objectID,
            isEnacted: false,
            shelfItems: []
        });
        await updateScene(scene,
            {
                modifyTime: Util.formatDate()
            });
        await createScene({
            objectID: scene.resource[0].objectID,
            createTime: Util.formatDate(),
            modifyTime: "",
            deleteTime: "",
            creatorName: 'ry',
            sceneName: sceneName,
            environmentID: env.resource[0].objectID,
            businessAccountID: 'Administrator',
            networkLoadLocked: false,
            contentTypes: ["FVREnvironment", "SceneShelf"],
            checkedOutUser: ""
        })
        // const data = await response.json();
        // return data;
    };

    const assetOperations = async (meshBody) => {
        try {
            await getMesh(meshBody.sceneID, meshBody.assetName);
            await createMesh(meshBody);
            let scene = await getSceneById(meshBody.sceneID, "");
            await updateScene(scene, {
                modifyTime: Util.formatDate()
            });
            await createScene(
                {
                    "objectID": scene.resource[0].objectID,
                    "createTime": Util.formatDate(),
                    "modifyTime": "",
                    "deleteTime": "",
                    "creatorName": "hypadmin",
                    "sceneName": scene.resource[0].sceneName,
                    "environmentID": scene.resource[0].environmentID,
                    "businessAccountID": scene.resource[0].businessAccountID,
                    "networkLoadLocked": false,
                    "contentTypes": [
                        "FVREnvironment",
                        "SceneShelf",
                        "MeshAsset"
                    ],
                    "checkedOutUser": ""
                }
            );
            let mesh = await getMesh(meshBody.sceneID, meshBody.assetName);
            await updateMesh(mesh, {
                modifyTime: Util.formatDate()
            })

            //this will be the mesh once it is uploaded and downloadable i believe. Check with Yao
            await createMesh({
                "objectID": meshBody.objectID,
                "createTime": Util.formatDate(),
                "modifyTime": "",
                "deleteTime": "",
                "creatorName": "hypadmin",
                "sceneID": meshBody.sceneID,
                "isEnacted": false,
                "assetName": meshBody.assetName,
                "relativeAssetPath": "TBD",
                "localAssetPath": "TBD",
                "displayAssetType": "",
                "downloadable": true,
                "loadCompatibility": 161,
                "realTransform": {
                    "position": {
                        "x": 0,
                        "y": 0,
                        "z": 0
                    },
                    "rotation": {
                        "x": 0,
                        "y": 0,
                        "z": 0,
                        "w": 1
                    },
                    "localScale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "createdByMeshScanningAgent": false,
                "makeVRInvisibleAfterLoading": false
            });
            createThreeDAsset({
                "objectID": Util.generateOID('ThreeDAssetDataBucket', 'TBD'),
                "createTime": Util.formatDate(),
                "modifyTime": "",
                "deleteTime": "",
                "creatorName": "TBD",
                "sceneID": scene.resource[0].objectID,
                "isEnacted": false,
                "sceneObjectIDs": [
                    mesh.resource[0].objectID
                ],
                "assetName": mesh.resource[0].assetName,
                "srcAssetPath": "TBD",
                "realTransform": {
                    "position": {
                        "x": 0,
                        "y": 0,
                        "z": 0
                    },
                    "rotation": {
                        "x": 0,
                        "y": 0,
                        "z": 0,
                        "w": 1
                    },
                    "localScale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "DefaultRenderType": 1,
                "highResMeshID": mesh.resource[0].objectID,
                "baseRawPclID": "",
                "lowResMeshID": "",
                "highResPclID": "",
                "lowResPclID": "",
                "collisionMeshAssetID": "",
                "isScanned": false,
                "exportScanMeshID": ""
            })
            scene = await getSceneById(meshBody.sceneID, "");
            console.log(scene)
            //TWO OPERATIONS BELOW ARE IDENTICAL TO TWO OPERATIONS THAT TOOK PLACE PREVIOUSLY. Check with Yao
            await updateScene(scene, {
                modifyTime: Util.formatDate()
            });
            scene.resource[0].contentTypes.push("ThreeDAsset")
            await createScene(scene.resource[0]);
            const threedasset = await getThreeDAsset(scene.resource[0].objectID, '')
            await createThreeDAssetTask({
                objectID: Util.generateOID('ThreeDAssetProcessTask, TBD'), 
                createTime: Util.formatDate(), 
                modifyTime: "", 
                deleteTime: "", 
                creatorName: "TBD", 
                status:0,
                sceneID:scene.resource[0].objectID,
                threeDAssetId:threedasset.resource[0].objectID,
                isDoubleSidedMeshRequired:false,
                genPclFromMeshEnabled:false,
                userConfig:{
                    isDoubleSidedMeshRequired:true,
                    genPclFromMeshEnabled:true,
                    limitLowResFaceCount:true,
                    lowResTextureSize_MB:500,
                    createSimpleColliders:true,
                    bypassErrorIfFailedProcessing:true
                }
            })
    
        } catch (e) {
                console.log(e)
            }
        }

    return (
            <ApiContext.Provider value={{ apiLogin, createScene, createEnvironment, createShelf, sceneOperations, getAllScenes, assetOperations, getSceneByName }}>
                {children}
            </ApiContext.Provider>
        );
    };

    export default ApiProvider;