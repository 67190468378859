import React from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { awsExports } from './aws-exports';
import { useState, useEffect } from 'react';
import Home from './components/Home'
import Navbar from './components/Navbar'
import ApiProvider from './context/ApiContext';
import { fetchAuthSession } from 'aws-amplify/auth';
import logo from './logo.png'

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: awsExports.USER_POOL_CLIENT_ID,
      userPoolId: awsExports.USER_POOL_ID,
      loginWith: {
        oauth: {
          domain: 'hypertunneldev.auth.us-east-1.amazoncognito.com',
          scopes: ['openid email phone profile aws.cognito.signin.user.admin'],
          redirectSignIn: ['http://localhost:3000/', 'https://example.com/'],
          redirectSignOut: ['http://localhost:3000/', 'https://example.com/'],
          responseType: 'code',
        },
        username: 'true',
        email: 'false',
        phone: 'false',
      }
    }
  }
});

function App() {
  const [jwtToken, setJwtToken] = useState('');

  useEffect(() => {
    fetchJwtToken();
  }, []);

  const fetchJwtToken = async () => {
    try {
      const session = await fetchAuthSession();
      const { idToken } = session.tokens?? {};
      // Directly convert the idToken to a string to get the JWT token
      const token = idToken.toString();
      console.log(token)
      setJwtToken(token);
    } catch (error) {
      console.log('Error fetching JWT token:', error);
    }
  };
  
  
  return (
    <Authenticator initialState='signIn'
    components={{
      SignUp: {
        FormFields() {
          <img src={logo} className="Nav-logo" alt="logo" />
          return (
            <>
              <Authenticator.SignUp.FormFields />

              {/* Custom fields for given_name and family_name */}
              <div><label>First name</label></div>
              <input
                type="text"
                name="given_name"
                placeholder="Please enter your first name"
              />
              <div><label>Last name</label></div>
              <input
                type="text"
                name="family_name"
                placeholder="Please enter your last name"
              />
              <div><label>Email</label></div>
              <input
                type="text"
                name="email"
                placeholder="Please enter a valid email"
              />


            </>
          );
        },
      },
    }}
    services={{
      async validateCustomSignUp(formData) {
        if (!formData.given_name) {
          return {
            given_name: 'First Name is required',
          };
        }
        if (!formData.family_name) {
          return {
            family_name: 'Last Name is required',
          };
        }
        if (!formData.email) {
          return {
            email: 'Email is required',
          };
        }
      },
    }}
    >
      {({ signOut, user}) => (

        
        <ApiProvider>
          
          <Navbar signOut={signOut}/>
          <Home/>
        </ApiProvider>
      )}
    </Authenticator>
  );
}

export default App;
