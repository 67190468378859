import * as React from 'react';
import { useState, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../logo.png'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import  Button  from '@mui/material/Button';
import '../App.css';
import { AuthContext } from '../context/AuthContext';


function Navbar({signOut}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(!menuOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  return (
    <AppBar position="static" style={{ backgroundColor: '#d3d3d3' }}>
    <Toolbar>
      <Typography variant="h6">
        <img src={logo} className="Nav-logo" alt="logo" />
      </Typography>
      {/* Wrap the logout button and the hamburger icon in a div */}
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
        <Button
          backgroundColor="white"
          aria-label="logout"
          style={{ marginRight: '2%', color: '#36368D' }}
          onClick={signOut} // Assuming you have a handleLogout function for logout logic
        >
          Logout
        </Button>
        <IconButton edge="end" color="inherit" aria-label="menu" style={{ color: '#36368D' }} onClick={handleMenuClick}>
          <MenuIcon />
        </IconButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>Scene Management</MenuItem>
        <MenuItem onClick={handleClose}>3D Asset Management</MenuItem>
      </Menu>
    </Toolbar>
  </AppBar>
  );
}


export default Navbar;